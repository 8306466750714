import React, { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import styles from "./MeasurementEntrySection.module.scss";

type MeasurementLocationProps = {
  fieldName: string;
  numAnchors: number;
  locationIndex: number;
};

export const MeasurementLocation: FC<MeasurementLocationProps> = ({ fieldName, numAnchors, locationIndex }) => {
  const { register } = useFormContext();

  const fields = useMemo(() => {
    return Array.from({ length: numAnchors }).map((_, index) => ({ id: index }));
  }, [numAnchors]);

  return (
    <>
      <div>L{locationIndex + 1}</div>
      {fields.map((item, index) => (
        <div key={item.id} className={styles.tableInputContainer}>
          <input
            data-testid={`location-${locationIndex}-anchor-${index}`}
            {...register(`${fieldName}.distances.${index}`)}
          />
        </div>
      ))}
    </>
  );
};
