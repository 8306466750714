import React, { FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { Device } from "../utils/types";
import { urls } from "../utils/constants";
import { useAuthContext } from "./AuthContext";

type DeviceContextType = {
  devices: Device[];
  isLoading: boolean;
  fetchDevices: (searchTerm?: string) => Promise<void>;
};

const DevicesContext = React.createContext<DeviceContextType | null>(null);

export const DevicesContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [devices, setDevices] = useState<Device[]>([]);
  const { token } = useAuthContext();
  const [isLoading, setLoading] = useState(true);

  const fetchDevices = async (searchTerm = "") => {
    try {
      setLoading(true);
      const response = await fetch(urls.devicesUrl(searchTerm), {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      const data = await response.json();
      setDevices(data.results as Device[]);
    } catch (error) {
      console.error("Error fetching devices:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  return <DevicesContext.Provider value={{ devices, isLoading, fetchDevices }}>{children}</DevicesContext.Provider>;
};

export const useDevicesContext = () => {
  const devicesContext = useContext(DevicesContext);

  if (devicesContext == null) {
    throw Error("No Device context found. You forgot to add a consumer/provider");
  }
  return devicesContext;
};
