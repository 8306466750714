import React, { FC, FocusEventHandler } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./FormField.module.scss";
import Form from "react-bootstrap/Form";

type FormFieldProps = {
  labelText: string;
  fieldName: string;
  placeholderText?: string;
  onBlur?: FocusEventHandler;
};
export const FormField: FC<FormFieldProps> = ({ fieldName, labelText, placeholderText, onBlur }) => {
  const { register } = useFormContext();
  return (
    <>
      <Form.Label htmlFor={fieldName} className={styles.formLabel}>
        {labelText}
      </Form.Label>
      <Form.Control
        {...register(fieldName)}
        id={fieldName}
        className={styles.formInput}
        placeholder={placeholderText}
        onBlur={onBlur}
      />
    </>
  );
};
