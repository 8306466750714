import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { dateFormat, urls } from "../../utils/constants";
import { useFormContext } from "react-hook-form";
import { useAuthContext } from "../../Context/AuthContext";
import { Device } from "../../utils/types";

export const useFetchTagData = (fieldName: string, configTags: Device[]) => {
  const { token } = useAuthContext();
  const { watch } = useFormContext();
  const { timestampFrom, timestampTo } = watch(fieldName);
  const [tagsWithNoData, setTagsWithNoData] = useState<Device[]>([]);
  useEffect(() => {
    const fetchTagData = async (dateFrom: DateTime, dateTo: DateTime) => {
      const queryParams = new URLSearchParams({
        start_timestamp: (dateFrom.toUnixInteger() * 1000).toString(),
        end_timestamp: (dateTo.toUnixInteger() * 1000).toString(),
      });
      const results = await Promise.all(
        configTags.map(async (tag) => {
          const response = await fetch(`${urls.deviceDataUrl(tag.deveui)}?${queryParams}`, {
            headers: { Authorization: `JWT ${token}` },
          });
          const dataList = await response.json();
          return { tag, hasData: dataList.length > 0 };
        })
      );
      setTagsWithNoData(results.filter(({ hasData }) => !hasData).map(({ tag }) => tag));
    };
    const from = DateTime.fromFormat(timestampFrom, dateFormat);
    const to = DateTime.fromFormat(timestampTo, dateFormat);

    if (from.isValid && to.isValid) {
      fetchTagData(from, to);
    }
  }, [timestampFrom, timestampTo]);

  return tagsWithNoData;
};
