import { FormProvider } from "react-hook-form";
import { SavedExperimentFormData } from "../shared";
import styles from "./CreateExperimentSubForm1.module.scss";
import Form from "react-bootstrap/Form";
import { SelectDevicesSection } from "../../SelectDevicesSection/SelectDevicesSection";
import React, { FC, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { SectionContainer } from "../../shared/SectionContainer/SectionContainer";
import { useSubForm1 } from "./useSubForm1";
import { DevicesContextProvider } from "../../../Context/DevicesContext";

type CreateExperimentSubForm1Props = {
  onSubmit: (data: SavedExperimentFormData) => void;
};
const CreateExperimentSubForm1Inner: FC<CreateExperimentSubForm1Props> = ({ onSubmit }) => {
  const { formUtils, onSubmit: onSubmitForm, formFilledCorrectly } = useSubForm1(onSubmit);
  const { register, watch } = formUtils;
  const watchedFields = watch();
  const [isFormCorrect, setIsFormCorrect] = useState(false);

  useEffect(() => {
    setIsFormCorrect(formFilledCorrectly());
  }, [watchedFields, formFilledCorrectly]);
  return (
    <FormProvider {...formUtils}>
      <form onSubmit={onSubmitForm}>
        <SectionContainer headline="Name and devices" prefix="subForm1">
          <div className={styles.mainContainer}>
            <div>
              <Form.Label htmlFor="experimentName">Experiment Name*</Form.Label>
              <Form.Control id="experimentName" {...register("experimentName")} />
            </div>
            <div>
              <SelectDevicesSection />
            </div>
            <div className={`${styles.submitButtonContainer}`}>
              <Button disabled={!isFormCorrect} type="submit">
                Next
              </Button>
            </div>
          </div>
        </SectionContainer>
      </form>
    </FormProvider>
  );
};

export const CreateExperimentSubForm1: FC<CreateExperimentSubForm1Props> = ({ ...props }) => (
  <DevicesContextProvider>
    <CreateExperimentSubForm1Inner {...props} />
  </DevicesContextProvider>
);
