import React, { FC } from "react";
import styles from "./SavingStateDisplay.module.scss";

export type SavingState = "none" | "pending" | "success" | "fail";

type SavingStateDisplayProps = {
  state: SavingState;
};

export const SavingStateDisplay: FC<SavingStateDisplayProps> = ({ state }) => {
  switch (state) {
    case "success":
      return <span className={styles.success}>Saved successfully!</span>;
    case "fail":
      return <span className={styles.fail}>Failed to save data!</span>;
    case "pending":
      return <span className={styles.pending}>Saving...</span>;
    default:
      return null;
  }
};
