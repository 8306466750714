import React, { FC, PropsWithChildren } from "react";

import styles from "./SectionContainer.module.scss";

type SectionContainerProps = {
  headline: string;
  prefix: string;
  onClickEditButton?: () => void;
  isFilledOut?: boolean;
} & PropsWithChildren;

export const SectionContainer: FC<SectionContainerProps> = ({
  headline,
  prefix,
  isFilledOut,
  onClickEditButton,
  children,
}) => {
  return (
    <div>
      <h2 className={styles.headline}>{headline}</h2>
      <section
        data-testid={`${prefix}-card`}
        className={isFilledOut ? styles.cardContainerFilledOut : styles.cardContainer}
        onClick={isFilledOut ? onClickEditButton : undefined}
      >
        <div className={styles.contentContainer}>{children}</div>
        <div className={styles.editIcon}>🖊️</div>
      </section>
    </div>
  );
};
