import React, { useState } from "react";
import { CreateExperimentSubForm1 } from "./CreateExperimentSubForm1/CreateExperimentSubForm1";
import { SavedExperimentFormData } from "./shared";
import { SectionContainer } from "../shared/SectionContainer/SectionContainer";
import { CreateExperimentSubForm2 } from "./CreateExperimentSubForm2/CreateExperimentSubForm2";
import { Button } from "react-bootstrap";
import styles from "./CreateExperimentForm.module.scss";

type CreateExperimentFormState =
  | {
      currentStep: "anchors & test runs";
      savedFormData: SavedExperimentFormData;
    }
  | {
      currentStep: "start or continue" | "name & devices";
    };

export const CreateExperimentForm = () => {
  const savedSessions = JSON.parse(localStorage.getItem("create-experiment-form-saved-sessions") || "{}");
  const savedSessionsKeys = Object.keys(savedSessions);

  const initialFormStep = savedSessionsKeys.length > 0 ? "start or continue" : "name & devices";
  const [experimentFormState, setFormState] = useState<CreateExperimentFormState>({
    currentStep: initialFormStep,
  });

  const onSubmitSubForm1 = (savedFormData: SavedExperimentFormData) => {
    setFormState({
      currentStep: "anchors & test runs",
      savedFormData,
    });
  };

  switch (experimentFormState.currentStep) {
    case "start or continue":
      return (
        <div className={styles.startOrContinueContainer}>
          <div>
            <Button onClick={() => setFormState({ currentStep: "name & devices" })}>Create new</Button>
          </div>
          {savedSessionsKeys.map((key) => (
            <div key={key}>
              <Button
                onClick={() => setFormState({ currentStep: "anchors & test runs", savedFormData: savedSessions[key] })}
                variant="secondary"
              >
                Continue editing {key}
              </Button>
            </div>
          ))}
        </div>
      );
    case "name & devices":
      return <CreateExperimentSubForm1 onSubmit={onSubmitSubForm1} />;
    case "anchors & test runs":
      return (
        <>
          <SectionContainer
            headline="Experiment"
            prefix={"selected-experiment"}
            isFilledOut={true}
            onClickEditButton={() => setFormState({ currentStep: "name & devices" })}
          >
            <div data-testid="selected-experiment-name">{experimentFormState.savedFormData.experimentName}</div>
          </SectionContainer>
          <CreateExperimentSubForm2
            experimentName={experimentFormState.savedFormData.experimentName}
            selectedDevices={experimentFormState.savedFormData.selectedDevices}
            initialFormData={experimentFormState.savedFormData}
          />
        </>
      );
  }
};
