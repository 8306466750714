import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { DateTime } from "luxon";
import { dateFormat } from "../../utils/constants";
import React, { FC, useState } from "react";
import { useFormContext } from "react-hook-form";

type TestRunTimerModalProps = {
  fieldName: string;
};

export const TestRunTimer: FC<TestRunTimerModalProps> = ({ fieldName }) => {
  const { setValue } = useFormContext();

  const [isModalVisible, setIsModalVisible] = useState(false);
  // Start time for From tag. Stop tag is local var in onclick function
  const [startTime, setStartTime] = useState<string>();
  return (
    <>
      <Button
        onClick={() => {
          setIsModalVisible(true);
          const currentTime = DateTime.now().toFormat(dateFormat);
          setStartTime(currentTime);
        }}
      >
        Start
      </Button>
      <Modal show={isModalVisible}>
        <Modal.Header>
          <Modal.Title>Tag Test Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div data-testid={"test-data-tagging-modal"}>Press stop to finish tagging data</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              const stoppedTime = DateTime.now().toFormat(dateFormat);
              setValue(`${fieldName}.timestampFrom`, startTime);
              setValue(`${fieldName}.timestampTo`, stoppedTime);
              setIsModalVisible(false);
            }}
          >
            Stop
          </Button>
          <Button
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
