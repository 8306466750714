import React, { FC } from "react";
import { useController, useFormContext } from "react-hook-form";
import DatePicker from "react-datepicker";
import styles from "./DateRangeFormField.module.scss";

import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";
import { dateFormat } from "../../../utils/constants";
import { Form } from "react-bootstrap";

type DateRangeFormFieldProps = {
  labelTextFrom: string;
  fieldNameFrom: string;
  labelTextTo: string;
  fieldNameTo: string;
};

const placeholderText = "Choose a date...";

export const DateRangeFormField: FC<DateRangeFormFieldProps> = ({
  fieldNameFrom,
  fieldNameTo,
  labelTextFrom,
  labelTextTo,
}) => {
  const { control } = useFormContext();
  const {
    field: { onChange: onChangeFrom, value: timestampFrom },
  } = useController({
    name: fieldNameFrom,
    control,
  });
  const {
    field: { onChange: onChangeTo, value: timestampTo },
  } = useController({
    name: fieldNameTo,
    control,
  });

  const startDateValidated = DateTime.fromFormat(timestampFrom, dateFormat);
  const endDateValidated = DateTime.fromFormat(timestampTo, dateFormat);
  const startDate = startDateValidated.isValid ? startDateValidated.toJSDate() : null;
  const endDate = endDateValidated.isValid ? endDateValidated.toJSDate() : null;

  return (
    <>
      <Form.Label htmlFor={fieldNameFrom} className={styles.formLabel}>
        <span>{labelTextFrom}</span>
        <DatePicker
          id={fieldNameFrom}
          selected={startDate}
          onChange={(date) => onChangeFrom(date ? DateTime.fromJSDate(date).toFormat(dateFormat) : "")}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat={dateFormat}
          showTimeInput
          placeholderText={placeholderText}
        />
      </Form.Label>
      <Form.Label htmlFor={fieldNameTo} className={styles.formLabel}>
        <span>{labelTextTo}</span>
        <DatePicker
          id={fieldNameTo}
          selected={endDate}
          onChange={(date) => onChangeTo(date ? DateTime.fromJSDate(date).toFormat(dateFormat) : "")}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat={dateFormat}
          showTimeInput
          placeholderText={placeholderText}
        />
      </Form.Label>
    </>
  );
};
