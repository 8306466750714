import { EstimationExperimentSimple, ParsedData, SolverResult, SolverResultSimple } from "../../utils/types";

export type SolverComparisonState = {
  selectedExperiment?: EstimationExperimentSimple;
  solverResults: Array<SolverResultSimple | SolverResult>;
  solverResultsData: Record<string, ParsedData[]>;
  selectedSolverResults: Record<string, boolean>;
  isSolverResultsLoaded: boolean;
};

type SetExperimentAction = {
  type: "SET_EXPERIMENT";
  experiment: EstimationExperimentSimple;
};

type SetSolverResultsAction = {
  type: "SET_SOLVER_RESULTS";
  solverResults: SolverResultSimple[];
};

type SetSolverResultDetailAction = {
  type: "SET_SOLVER_RESULT_DETAIL";
  solverResult: SolverResult;
};

type SetSolverResultDataAction = {
  type: "SET_SOLVER_RESULT_DATA";
  data: ParsedData[];
  name: string;
};

type ToggleSolverSelectedAction = {
  type: "TOGGLE_SOLVER_SELECTED";
  name: string;
};

export type SolverComparisonAction =
  | SetSolverResultsAction
  | SetSolverResultDetailAction
  | SetExperimentAction
  | ToggleSolverSelectedAction
  | SetSolverResultDataAction;

export const solverComparisorReducer = (
  state: SolverComparisonState,
  action: SolverComparisonAction
): SolverComparisonState => {
  switch (action.type) {
    case "SET_EXPERIMENT":
      return {
        ...state,
        selectedExperiment: action.experiment,
      };
    case "SET_SOLVER_RESULTS":
      return {
        ...state,
        isSolverResultsLoaded: true,
        solverResults: action.solverResults,
        selectedSolverResults: action.solverResults.reduce<Record<string, boolean>>((accumulator, currentValue) => {
          accumulator[currentValue.name] = false;
          return accumulator;
        }, {}),
      };
    case "SET_SOLVER_RESULT_DETAIL":
      const existingSolverResultIndex = state.solverResults.findIndex(({ name }) => name == action.solverResult.name);
      const updatedSolverResults = [...state.solverResults];
      updatedSolverResults[existingSolverResultIndex] = action.solverResult;
      return {
        ...state,
        solverResults: updatedSolverResults,
      };
    case "SET_SOLVER_RESULT_DATA":
      return {
        ...state,
        solverResultsData: { ...state.solverResultsData, [action.name]: action.data },
      };
    case "TOGGLE_SOLVER_SELECTED":
      return {
        ...state,
        selectedSolverResults: {
          ...state.selectedSolverResults,
          [action.name]: !state.selectedSolverResults[action.name],
        },
      };
  }
};
