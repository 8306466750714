import React, { FC } from "react";
import styles from "./LocationGroup.module.scss";
import { useFormContext } from "react-hook-form";
import { MeasurementLocation } from "./MeasurementLocation";
import { TestFormData } from "../ConfigAnchorsSection/shared";
import { Device } from "../../utils/types";
import { DateRangeFormField } from "../shared/DateRangeFormField/DateRangeFormField";
import { useFetchTagData } from "./useFetchTagData";
import { TestRunTimer } from "./TestRunTimer";

type LocationGroupFormProps = {
  groupIndex: number;
  fieldName: string;
  numAnchors: number;
  configTags: Device[];
};

export const LocationGroup: FC<LocationGroupFormProps> = ({ groupIndex, fieldName, numAnchors, configTags }) => {
  const { watch } = useFormContext();
  const locationNumber = groupIndex + 1;
  const anchors: TestFormData["anchors"] = watch("anchors");

  const tagsWithNoData = useFetchTagData(fieldName, configTags);

  return (
    <div className={styles.groupContainer} data-testid={`test-run-${locationNumber}-container`}>
      <div className={styles.groupLabel}>
        <label>Test run {locationNumber}</label>
      </div>
      <div className={styles.staticDataContainer}>
        <DateRangeFormField
          fieldNameFrom={`${fieldName}.timestampFrom`}
          fieldNameTo={`${fieldName}.timestampTo`}
          labelTextFrom="from"
          labelTextTo="to"
        />
        <TestRunTimer fieldName={fieldName} />
      </div>
      <div className={styles.warningMessage}>
        {tagsWithNoData.map(({ deveui, name }) => (
          <div key={deveui}>No data found for {name} in this time range.</div>
        ))}
      </div>
      <div className={styles.locationsGrid} style={{ gridTemplateColumns: `auto repeat(${numAnchors}, 1fr)` }}>
        <div></div>
        {anchors.map(({ label }) => (
          <div key={label}>{label}</div>
        ))}
        {configTags.map(({ deveui }, index) => (
          <MeasurementLocation
            key={deveui}
            fieldName={`${fieldName}.locations.${index}`}
            numAnchors={anchors.length}
            locationIndex={index}
          />
        ))}
      </div>
    </div>
  );
};
