import { SelectExperimentSection } from "../SelectExperimentSection/SelectExperimentSection";
import React, { useEffect, useReducer } from "react";
import { SelectSolverResultsSection } from "../SelectSolverResultsSection/SelectSolverResultsSection";
import { EstimationExperimentSimple } from "../../utils/types";
import { SolverResultsDisplay } from "../SolverResultsDisplay/SolverResultsDisplay";
import { isFullSolverResult } from "../../utils/typeGuards";
import { solverComparisorReducer } from "./solverComparisonReducer";
import { useRequestHelpers } from "./useRequestHelpers";

export const SolverComparisonForm = () => {
  const [
    { selectedExperiment, solverResults, isSolverResultsLoaded, solverResultsData, selectedSolverResults },
    dispatch,
  ] = useReducer(solverComparisorReducer, {
    isSolverResultsLoaded: false,
    selectedExperiment: undefined,
    solverResults: [],
    solverResultsData: {},
    selectedSolverResults: {},
  });

  const setSelectedExperiment = (experiment: EstimationExperimentSimple) =>
    dispatch({ type: "SET_EXPERIMENT", experiment });

  const { fetchExperimentDetail, fetchSolverResultData } = useRequestHelpers(dispatch);

  useEffect(() => {
    if (selectedExperiment != null) {
      fetchExperimentDetail(selectedExperiment);
    }
  }, [selectedExperiment]);

  useEffect(() => {
    Object.keys(selectedSolverResults)
      .filter((name) => selectedSolverResults[name])
      .filter((name) => !(name in solverResultsData))
      .forEach((name) => {
        const result = solverResults.find((result) => result.name === name);
        if (result != null && isFullSolverResult(result)) {
          fetchSolverResultData(result);
        }
      });
  }, [selectedSolverResults, solverResults]);

  return (
    <>
      <SelectExperimentSection selectedExperiment={selectedExperiment} setSelectedExperiment={setSelectedExperiment} />
      {isSolverResultsLoaded && (
        <SelectSolverResultsSection
          solverResults={solverResults}
          selectedSolverResults={selectedSolverResults}
          dispatch={dispatch}
        />
      )}
      {isSolverResultsLoaded && (
        <SolverResultsDisplay selectedSolverResults={selectedSolverResults} solverResultsData={solverResultsData} />
      )}
    </>
  );
};
