import React, { FC } from "react";
import { CreateExperimentForm } from "../../Components/CreateExperimentForm/CreateExperimentForm";
import styles from "./MainPage.module.scss";

export const MainPage: FC = () => {
  return (
    <>
      <header>
        <h1>Data Analysis Dashboard</h1>
      </header>
      <main className={styles.pageContainer}>
        <CreateExperimentForm />
      </main>
    </>
  );
};
