import React from "react";
import { useFormContext } from "react-hook-form";
import { SectionContainer } from "../shared/SectionContainer/SectionContainer";
import { AnchorForm } from "./AnchorForm";
import { AnchorPositionsPlot } from "../AnchorPositionsPlot/AnchorPositionsPlot";
import { ExperimentAnchorsAndTestRuns } from "../CreateExperimentForm/shared";

export const ConfigAnchorsSection = () => {
  const { watch } = useFormContext<ExperimentAnchorsAndTestRuns>();

  const selectedAnchors = watch("anchors");
  return (
    <SectionContainer headline="Configure Anchors" prefix="anchors">
      {selectedAnchors.map((anchor, index) => (
        <AnchorForm key={anchor.label} anchorIndex={index} fieldName={`anchors.${index}`} />
      ))}
      <AnchorPositionsPlot anchors={selectedAnchors} />
    </SectionContainer>
  );
};
