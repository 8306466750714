import React, { FC } from "react";
import { Config, Layout, PlotData } from "plotly.js";
import Plot from "react-plotly.js";

import styles from "./AnchorPositionsPlot.module.scss";
import { geo2cartesian } from "./utils";
import { AnchorConfig } from "../../utils/types";

type AnchorPositionsPlotProps = {
  anchors: AnchorConfig[];
};
export const AnchorPositionsPlot: FC<AnchorPositionsPlotProps> = ({ anchors }) => {
  if (anchors.length === 0) {
    return <></>;
  }

  const geoLocations = anchors.map(({ lat, lng, altitude }) => ({
    lat: parseFloat(lat || "0.0"),
    lng: parseFloat(lng || "0.0"),
    altitude: parseFloat(altitude),
  }));

  const cartesianLocations = geo2cartesian(geoLocations, geoLocations[0]);

  const data: Partial<PlotData>[] = [
    {
      type: "scatter",
      mode: "text+markers",
      colorscale: "blackbody",
      textposition: "bottom center",
      x: cartesianLocations.map(({ x }) => x),
      y: cartesianLocations.map(({ y }) => y),
      text: Object.values(anchors).map(({ label }) => label),
      marker: {
        color: "Hardware",
        line: { color: "DarkSlateGrey", width: 1 },
        symbol: 5,
        size: 24,
      },
    },
  ];

  const layout: Partial<Layout> = {
    title: "",
    yaxis: { scaleanchor: "x", scaleratio: 1 },
  };

  const config: Partial<Config> = {
    scrollZoom: true,
  };

  return (
    <div className={styles.plotContainer}>
      <Plot data={data} layout={layout} config={config} />
    </div>
  );
};
