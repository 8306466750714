import React, { FC } from "react";
import Form from "react-bootstrap/Form";
import { SectionContainer } from "../shared/SectionContainer/SectionContainer";
import { SolverResult, SolverResultSimple } from "../../utils/types";
import { DateTime } from "luxon";
import styles from "./SelectSolverResultsSection.module.scss";
import { isFullSolverResult } from "../../utils/typeGuards";
import { SolverComparisonAction } from "../SolverComparisonForm/solverComparisonReducer";

type SelectSolverResultsSectionProps = {
  solverResults: Array<SolverResultSimple | SolverResult>;
  selectedSolverResults: Record<string, boolean>;
  dispatch: React.Dispatch<SolverComparisonAction>;
};

const formatDate = (isoString: string) => DateTime.fromISO(isoString).toFormat("dd/MM/y, T");

export const SelectSolverResultsSection: FC<SelectSolverResultsSectionProps> = ({
  solverResults,
  selectedSolverResults,
  dispatch,
}) => {
  const onChange = (solverResultName: string) => {
    dispatch({ type: "TOGGLE_SOLVER_SELECTED", name: solverResultName });
  };
  return (
    <SectionContainer headline="Select solvers to compare" prefix="solver-results">
      <div className={styles.solverResultsGrid}>
        <div></div>
        <h3>Solver name</h3>
        <h3>Last updated</h3>
        <div></div>
        {solverResults.map((solverResult) => (
          <React.Fragment key={solverResult.name}>
            <Form.Check
              id={`solver-result-${solverResult.name}`}
              onChange={() => onChange(solverResult.name)}
              checked={selectedSolverResults[solverResult.name]}
            />
            <Form.Label htmlFor={`solver-result-${solverResult.name}`} className={styles.checkLabel}>
              {solverResult.name}
            </Form.Label>
            {isFullSolverResult(solverResult) && <div>{formatDate(solverResult.updated_at)}</div>}
            <div className={styles.solverNote}>Uploaded from Colab</div>
          </React.Fragment>
        ))}
      </div>
    </SectionContainer>
  );
};
