import React, { FC } from "react";
import { FormProvider } from "react-hook-form";
import styles from "./CreateExperimentSubForm2.module.scss";
import { MeasurementEntrySection } from "../../MeasurementEntrySection/MeasurementEntrySection";
import Button from "react-bootstrap/Button";
import { ConfigAnchorsSection } from "../../ConfigAnchorsSection/ConfigAnchorsSection";
import { ExperimentAnchorsAndTestRuns } from "../shared";
import { Device } from "../../../utils/types";
import { SavingStateDisplay } from "../../shared/SavingStateDisplay/SavingStateDisplay";
import { useSubForm2 } from "./useSubForm2";

type CreateExperimentSubForm2Props = {
  experimentName: string;
  selectedDevices: Device[];
  initialFormData: ExperimentAnchorsAndTestRuns;
};

export const CreateExperimentSubForm2: FC<CreateExperimentSubForm2Props> = ({
  experimentName,
  selectedDevices,
  initialFormData,
}) => {
  const { formUtils, onSubmit, savingState } = useSubForm2(experimentName, selectedDevices, initialFormData);

  return (
    <FormProvider {...formUtils}>
      <form className={styles.mainContainer} onSubmit={onSubmit}>
        <ConfigAnchorsSection />
        <MeasurementEntrySection selectedDevices={selectedDevices} />
        <div data-testid="save-measurement-data-container" className={styles.controlsContainer}>
          <div>
            <Button type="submit">Create experiment</Button>
          </div>
          <SavingStateDisplay state={savingState} />
        </div>
      </form>
    </FormProvider>
  );
};
