type Location = {
  lat: number;
  lng: number;
  altitude: number;
};

type Point = {
  x: number;
  y: number;
  z: number;
};

const earthRadius = 6.371e6;

function to_radians(degrees: number) {
  return degrees * (Math.PI / 180);
}
function to_degrees(radians: number) {
  return radians / (Math.PI / 180);
}

export const bearing = (geo: Location[], ref: Location): number[] => {
  // In:
  // 1. List of tuples with lat and long
  // 2. tuple with reference lat and long
  // Out:
  // Azimuth in degrees with respect to reference lat and long

  const reference_lat = to_radians(ref.lat);
  const reference_lon = to_radians(ref.lng);

  const phi: number[] = [];

  geo.forEach((g) => {
    const geo_lat = to_radians(g.lat);
    const geo_lon = to_radians(g.lng);

    let angle = Math.atan2(
      Math.sin(geo_lon - reference_lon) * Math.cos(geo_lat),
      Math.cos(reference_lat) * Math.sin(geo_lat) -
        Math.sin(reference_lat) * Math.cos(geo_lat) * Math.cos(geo_lon - reference_lon)
    );

    angle = to_degrees(angle);
    // Correct [-180, 180] to usual azimuth [0, 360]
    if (angle < 0) {
      angle += 360;
    }

    phi.push(angle);
  });
  return phi;
};

const haversine = (geo: Location[], ref: Location): number[] => {
  const reference_lat = to_radians(ref.lat);
  const reference_lon = to_radians(ref.lng);

  const angleList: number[] = [];

  geo.forEach((g) => {
    const geo_lat = to_radians(g.lat);
    const geo_lon = to_radians(g.lng);

    let angle = Math.sin(Math.pow((reference_lat - geo_lat) / 2, 2));
    angle += Math.cos(geo_lat) * Math.cos(reference_lat) * Math.sin(Math.pow((reference_lon - geo_lon) / 2, 2));
    if (angle >= 0) {
      angle = 2 * earthRadius * Math.asin(Math.sqrt(angle));
    } else {
      angle = NaN;
      console.warn("Warning: Negative angle into haversine");
    }

    angleList.push(angle);
  });
  return angleList;
};

export const geo2cartesian = (geo: Location[], ref: Location): Point[] => {
  const hs = haversine(geo, ref);
  const azimuth = bearing(geo, ref);

  const cartesianPoints: Point[] = [];
  const theta: number[] = [];

  azimuth.forEach((az) => {
    if (az >= 0 && az <= 90) {
      theta.push(to_radians(90 - az));
    } else {
      theta.push(to_radians(450 - az));
    }
  });

  for (let i = 0; i < hs.length; i++) {
    const x = Math.cos(theta[i]) * hs[i];
    const y = Math.sin(theta[i]) * hs[i];
    cartesianPoints.push({ x, y, z: geo[i].altitude });
  }
  return cartesianPoints;
};
