import styles from "./AnchorForm.module.scss";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormField } from "../shared/FormField/FormField";

type AnchorFormProps = {
  anchorIndex: number;
  fieldName: string;
};

export const AnchorForm: FC<AnchorFormProps> = ({ fieldName, anchorIndex }) => {
  const { watch } = useFormContext();
  return (
    <div className={styles.mainContainer} data-testid={`anchor${anchorIndex}-geolocation`}>
      <h3 className={styles.anchorLabel}>{watch(`${fieldName}.label`)}</h3>
      <FormField labelText="lat" fieldName={`${fieldName}.lat`} />
      <FormField labelText="lng" fieldName={`${fieldName}.lng`} />
      <FormField labelText="altitude" fieldName={`${fieldName}.altitude`} />
    </div>
  );
};
