import React, { FC } from "react";
import { SectionContainer } from "../shared/SectionContainer/SectionContainer";
import { useFieldArray, useFormContext } from "react-hook-form";
import { TestFormData } from "../ConfigAnchorsSection/shared";
import Button from "react-bootstrap/Button";
import { LocationGroup } from "./LocationGroup";
import { Device } from "../../utils/types";

type MeasurementEntrySectionProps = { selectedConfigName?: string; selectedDevices: Array<Device> };

const filterLoadedDevice = (device: Device): device is Device => {
  return Object.keys(device).includes("type");
};

export const MeasurementEntrySection: FC<MeasurementEntrySectionProps> = ({ selectedDevices }) => {
  const selectedTags = selectedDevices
    .filter(filterLoadedDevice)
    .filter((device) => !device.type.name.includes("Anchor"));
  const { control, watch } = useFormContext<TestFormData>();
  const { fields, append } = useFieldArray({ control, name: "testRuns" });
  const anchors = watch("anchors");
  const addTestRun = () => {
    append({
      locations: selectedTags.map(() => ({
        distances: [],
      })),
      timestampFrom: "",
      timestampTo: "",
    });
  };

  return (
    <SectionContainer headline={"Enter Measurements"} prefix="measurements">
      {fields.map((item, index) => (
        <LocationGroup
          key={item.id}
          groupIndex={index}
          fieldName={`testRuns.${index}`}
          numAnchors={anchors.length}
          configTags={selectedTags}
        />
      ))}
      <Button variant="secondary" onClick={addTestRun} type="button">
        Add test run
      </Button>
    </SectionContainer>
  );
};
