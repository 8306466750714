import { useForm } from "react-hook-form";
import { ExperimentNameAndDevices, SavedExperimentFormData } from "../shared";

export const useSubForm1 = (onSubmitCallback: (data: SavedExperimentFormData) => void) => {
  const formUtils = useForm<ExperimentNameAndDevices>({
    defaultValues: {
      experimentName: "",
      selectedDevices: [],
    },
  });
  const formFilledCorrectly = () => {
    const { experimentName, selectedDevices } = formUtils.getValues();

    const hasExperimentName = experimentName !== "" ? true : false;
    const atLeastOneDeviceSelected = selectedDevices.length >= 1 ? true : false;

    if (hasExperimentName && atLeastOneDeviceSelected) {
      return true;
    }

    return false;
  };

  const onSubmit = ({ experimentName, selectedDevices }: ExperimentNameAndDevices) => {
    const savedFormData = {
      experimentName,
      selectedDevices,
      anchors: selectedDevices
        .filter((device) => device.type.name.includes("Anchor"))
        .map((anchor, index) => ({
          ...anchor,
          label: `PA${index}`,
          lat: "",
          lng: "",
          altitude: "2.2",
        })),
      testRuns: [
        {
          timestampFrom: "",
          timestampTo: "",
          locations: selectedDevices
            .filter((device) => !device.type.name.includes("Anchor"))
            .map(() => ({
              distances: [],
            })),
        },
      ],
    };
    localStorage.setItem("create-experiment-form-saved-sessions", JSON.stringify({ [experimentName]: savedFormData }));
    onSubmitCallback(savedFormData);
  };
  return { formUtils, onSubmit: formUtils.handleSubmit(onSubmit), formFilledCorrectly };
};
