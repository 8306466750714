import React from "react";
import styles from "./DashboardPage.module.scss";
import { SolverComparisonForm } from "../../Components/SolverComparisonForm/SolverComparisonForm";

export const DashboardPage = () => {
  return (
    <main className={styles.pageContainer}>
      <SolverComparisonForm />
    </main>
  );
};
