import React, { useState } from "react";
import { useDevicesContext } from "../../Context/DevicesContext";
import { useFilterTable } from "./useFilterTable";
import { DeviceTable } from "../shared/DeviceTable/DeviceTable";
import { useController, useFormContext } from "react-hook-form";
import { ExperimentNameAndDevices } from "../CreateExperimentForm/shared";
import { useSelectTable } from "./useSelectTable";
import styles from "./DeviceTableSection.module.scss";

export const DeviceTableSection = () => {
  const [filterInput, setFilterInput] = useState("");
  const { devices, isLoading } = useDevicesContext();
  const { control } = useFormContext<ExperimentNameAndDevices>();
  const { field: selectedDevicesField } = useController({
    name: "selectedDevices",
    control,
  });

  const { handleFilterChange } = useFilterTable();

  const toggleColumnSelection = useSelectTable(selectedDevicesField.value, selectedDevicesField.onChange);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilterInput(value);
    handleFilterChange(value);
  };

  return (
    <>
      <div>
        <label htmlFor="filterInput" className={styles.filterInputLabel}>
          Filter:
        </label>
        <input
          data-testid="filterInputTest"
          id="filterInput"
          type="text"
          value={filterInput}
          onChange={handleInputChange}
        />
      </div>

      <div className={styles.mainContainer}>
        <div className={styles.tableContainer}>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <DeviceTable
              selectedDevices={selectedDevicesField.value}
              selectedDevicesOnChange={toggleColumnSelection}
              tableData={devices}
            />
          )}
        </div>
        <div className={styles.tableContainer}>
          {selectedDevicesField.value.length > 0 ? (
            <DeviceTable
              selectedDevices={selectedDevicesField.value}
              selectedDevicesOnChange={toggleColumnSelection}
              tableData={selectedDevicesField.value}
            />
          ) : (
            <p>No columns selected.</p>
          )}
        </div>
      </div>
    </>
  );
};
