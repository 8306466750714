import React from "react";
import { DeviceTableSection } from "./DeviceTableSection";
import styles from "./DeviceTableSection.module.scss";

export const SelectDevicesSection = () => {
  return (
    <>
      <h2 className={styles.deviceLabel}>Devices</h2>
      <div>
        <DeviceTableSection />
      </div>
    </>
  );
};
