const deviceDataTemplateUrl = process.env.REACT_APP_DEVICE_DATA_URL ?? "/api/v1/devices/{deveui}/data/";
export const urls = {
  estimationExperimentsUrl: process.env.REACT_APP_EXPERIMENTS_URL ?? "/api/v1/internal/estimation/experiments/",
  devicesUrl: (searchTerm: string) => `${process.env.REACT_APP_DEVICES_URL ?? "/api/v1/devices/"}?search=${searchTerm}`,
  tokenVerifyUrl: process.env.REACT_APP_TOKEN_VERIFY_URL ?? "/api/v1/token-verify/",
  tokenAuthUrl: process.env.REACT_APP_TOKEN_AUTH_URL ?? "/api/v1/token-auth/",
  deviceDataUrl: (deveui: string) => deviceDataTemplateUrl.replace("{deveui}", deveui),
};

export const dateFormat = "MMMM dd, yyyy HH:mm";
