import React, { FC, useEffect, useState } from "react";
import { SectionContainer } from "../shared/SectionContainer/SectionContainer";
import styles from "./SelectExperimentSection.module.scss";
import Form from "react-bootstrap/Form";
import { urls } from "../../utils/constants";
import { EstimationExperimentSimple } from "../../utils/types";
import { useAuthContext } from "../../Context/AuthContext";

type SelectExperimentSectionProps = {
  selectedExperiment: EstimationExperimentSimple | undefined;
  setSelectedExperiment: (experiment: EstimationExperimentSimple) => void;
};

export const SelectExperimentSection: FC<SelectExperimentSectionProps> = ({
  selectedExperiment,
  setSelectedExperiment,
}) => {
  const [experiments, setExperiments] = useState<EstimationExperimentSimple[]>([]);

  const { token } = useAuthContext();

  useEffect(() => {
    const fetchExperiments = async () => {
      const response = await fetch(urls.estimationExperimentsUrl, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      const responseData = await response.json();

      setExperiments(responseData.results);
    };
    fetchExperiments();
  }, []);
  return (
    <SectionContainer headline={"Select experiment"} prefix={"experiment"}>
      <div>
        <div className={styles.searchBar}>
          <Form.Label>Search:</Form.Label>
          <Form.Control />
        </div>
        {experiments.map((experiment) => (
          <Form.Check
            key={experiment.name}
            type={"radio"}
            label={experiment.name}
            id={`dataset-${experiment.name}`}
            checked={experiment.name === selectedExperiment?.name}
            onChange={() => setSelectedExperiment(experiment)}
          />
        ))}
      </div>
    </SectionContainer>
  );
};
