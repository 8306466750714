import React from "react";
import "./App.scss";
import { MainPage } from "./Pages/MainPage/MainPage";
import { LoginPage } from "./Pages/LoginPage/LoginPage";
import { AuthProvider, useAuthContext } from "./Context/AuthContext";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { DashboardPage } from "./Pages/DashboardPage/DashboardPage";

function App() {
  return (
    <AuthProvider>
      <Pages />
    </AuthProvider>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/dashboard",
    element: <DashboardPage />,
  },
]);

const Pages = () => {
  const { isLoggedIn, isLoading } = useAuthContext();

  if (isLoading) {
    return (
      <div className="App">
        <div>Loading...</div>
      </div>
    );
  }
  return <div className="App">{isLoggedIn ? <RouterProvider router={router} /> : <LoginPage />}</div>;
};

export default App;
