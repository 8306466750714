import { useForm } from "react-hook-form";
import { ExperimentAnchorsAndTestRuns } from "../shared";
import { useEffect, useState } from "react";
import { SavingState } from "../../shared/SavingStateDisplay/SavingStateDisplay";
import { useAuthContext } from "../../../Context/AuthContext";
import Cookies from "js-cookie";
import { dateFormat, urls } from "../../../utils/constants";
import { DateTime } from "luxon";
import { Device } from "../../../utils/types";

export const useSubForm2 = (
  experimentName: string,
  selectedDevices: Device[],
  initialFormData: ExperimentAnchorsAndTestRuns
) => {
  const formUtils = useForm<ExperimentAnchorsAndTestRuns>({
    defaultValues: initialFormData,
  });

  const values = formUtils.watch();
  useEffect(() => {
    localStorage.setItem("create-experiment-form-saved-sessions", JSON.stringify({ [experimentName]: values }));
  }, [experimentName, values]);
  const [savingState, setSavingState] = useState<SavingState>("none");

  const { token } = useAuthContext();

  const onSubmit = async (data: ExperimentAnchorsAndTestRuns) => {
    setSavingState("pending");
    const csrfToken = Cookies.get("csrftoken") ?? "";

    const { status } = await fetch(urls.estimationExperimentsUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        name: experimentName,
        device_deveuis: selectedDevices.map(({ deveui }) => deveui),
        anchor_configs: data.anchors.map(({ deveui, lat, lng, altitude }) => ({
          anchor: deveui,
          lat,
          lng,
          alt: altitude,
        })),
        test_runs: data.testRuns.map(({ locations, timestampTo, timestampFrom }) => ({
          start_timestamp: DateTime.fromFormat(timestampFrom, dateFormat).toISO(),
          end_timestamp: DateTime.fromFormat(timestampTo, dateFormat).toISO(),
          locations: locations.map(({ distances }) => ({ anchor_distances: distances })),
        })),
      }),
    });
    if (status !== 201) {
      setSavingState("fail");
      return;
    }
    setSavingState("success");
  };
  return { formUtils, onSubmit: formUtils.handleSubmit(onSubmit), savingState };
};
