import { useDevicesContext } from "../../Context/DevicesContext";
import { Device } from "../../utils/types";

export const useSelectTable = (selectedDevices: Device[], selectedDevicesOnChange: (devices: Device[]) => void) => {
  const { devices } = useDevicesContext();

  const selectAllDevices = () => {
    const filteredDevices = devices.filter((device) => !selectedDevices.some(({ deveui }) => deveui === device.deveui));

    let updatedDevices: Device[] = [];

    if (filteredDevices.length === 0) {
      updatedDevices = [];
    } else if (filteredDevices.length === devices.length - selectedDevices.length) {
      updatedDevices = [...devices];
    } else {
      updatedDevices = [...selectedDevices, ...filteredDevices];
    }

    selectedDevicesOnChange(updatedDevices);
  };

  const toggleColumnSelection = (columnId: string) => {
    if (columnId === "selectAll") {
      selectAllDevices();
    } else {
      const existingIndex = selectedDevices.findIndex(({ deveui }) => deveui === columnId);

      if (existingIndex !== -1) {
        const updatedDevices = selectedDevices.filter(({ deveui }) => deveui !== columnId);
        selectedDevicesOnChange(updatedDevices);
      } else {
        const foundDevice = devices.find(({ deveui }) => deveui === columnId);
        if (foundDevice) {
          const updatedDevices = [...selectedDevices, foundDevice];
          selectedDevicesOnChange(updatedDevices);
        }
      }
    }
  };

  return toggleColumnSelection;
};
