import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { urls } from "../../utils/constants";
import styles from "./LoginPage.module.scss";
import { useAuthContext } from "../../Context/AuthContext";
import Button from "react-bootstrap/Button";

type LoginFormData = {
  username: string;
  password: string;
};

export const LoginPage: FC = () => {
  const { register, handleSubmit } = useForm<LoginFormData>();
  const { onLogIn } = useAuthContext();
  const onSubmit = async (formData: LoginFormData) => {
    const response = await fetch(urls.tokenAuthUrl, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 201) {
      const token = (await response.json()).token;
      onLogIn(token);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <h1 className={styles.formHeader}>Log in</h1>
      <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="username">Username</label>
        <input id="username" {...register("username")} />
        <label htmlFor="password">Password</label>
        <input id="password" type="password" {...register("password")} />
        <Button type="submit" className={styles.submitButton}>
          Submit
        </Button>
      </form>
    </div>
  );
};
