// DeviceTable.tsx
import React, { FC } from "react";
import { Column, useTable, Row } from "react-table";
import { Device } from "../../../utils/types";
import styles from "./DeviceTable.module.scss";

type DeviceTableProps = {
  selectedDevices: Device[];
  selectedDevicesOnChange: (deveui: string) => void;
  tableData: Device[];
};

export const DeviceTable: FC<DeviceTableProps> = ({ selectedDevices, selectedDevicesOnChange, tableData }) => {
  const columns: Column<Device>[] = React.useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            checked={selectedDevices.length === tableData.length}
            onChange={() => selectedDevicesOnChange("selectAll")}
            data-testid="selectAllTest"
            className={styles.checkboxInput}
          />
        ),
        id: "selectAll",
        Cell: ({ row }: { row: Row<Device> }) => (
          <input
            type="checkbox"
            checked={selectedDevices.some(({ deveui }) => deveui === row.original.deveui)}
            data-testid={`checkbox-${row.original.deveui}`}
            onChange={() => selectedDevicesOnChange(row.original.deveui)}
            className={styles.checkboxInput}
          />
        ),
      },
      {
        Header: "Type",
        accessor: (device: Device) => device.type.classification,
      },
      {
        Header: "Name",
        accessor: "name",
      },
    ],
    [selectedDevices, selectedDevicesOnChange]
  );

  const tableInstance = useTable({ columns, data: tableData });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div className={styles.tableContainer}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id} data-testid={`row-${row.id}`}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} key={cell.column.id}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
