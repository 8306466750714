import React, { FC, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Layout, PlotData } from "plotly.js";

import styles from "./SolverResultsDisplay.module.scss";
import { ParsedData } from "../../utils/types";
import Plot from "react-plotly.js";

const generateCDF = (data: ParsedData[]) => {
  const numPoints = data.length;
  data.sort((a, b) => a.Error_2D - b.Error_2D);

  return {
    e: data.map((value) => value.Error_2D),
    p: data.map((_, index) => (100.0 * (index + 1)) / numPoints),
  };
};

type CDFPlotProps = {
  selectedSolverResults: Record<string, boolean>;
  solverResultsData: Record<string, ParsedData[]>;
};

export const SolverResultsDisplay: FC<CDFPlotProps> = ({ selectedSolverResults, solverResultsData }) => {
  const cdfs = useMemo(
    () =>
      Object.keys(solverResultsData)
        .filter((name) => selectedSolverResults[name])
        .map((name) => {
          return {
            name,
            ...generateCDF(solverResultsData[name]),
          };
        }),
    [solverResultsData, selectedSolverResults]
  );
  const data: Partial<PlotData>[] = cdfs.map(({ e, p, name }) => ({
    type: "scatter",
    mode: "lines+markers",
    colorscale: "blackbody",
    textposition: "bottom center",
    x: e,
    y: p,
    name,
  }));
  const [containerSize, setContainerSize] = useState([0, 0]);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    if (containerRef.current == null) {
      return;
    }
    const { width, height } = containerRef.current.getBoundingClientRect();
    setContainerSize([width, height]);
  };

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const layout: Partial<Layout> = {
    title: "CDFs comparison",
    titlefont: {
      size: 24,
    },
    width: containerSize[0],
    height: containerSize[1],
    xaxis: { title: "Absolute Error" },
    yaxis: { title: "Probability" },
  };
  return (
    <div className={styles.plotContainer} ref={containerRef}>
      {data.length > 0 && <Plot data={data} layout={layout} />}
    </div>
  );
};
