import React, { FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { urls } from "../utils/constants";

type AuthContextType = {
  token: string | undefined;
  onLogIn: (token: string) => void;
  isLoggedIn: boolean;
  isLoading: boolean;
};
export const AuthContext = React.createContext<AuthContextType | null>(null);

const tokenVerify = async (token: string) => {
  const response = await fetch(urls.tokenVerifyUrl, {
    method: "POST",
    body: JSON.stringify({ token }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.status === 201;
};

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const initialToken = localStorage.getItem("token") ?? "";
  const [token, setToken] = useState<string>(initialToken);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      if (token && (await tokenVerify(token))) {
        setIsLoggedIn(true);
      }

      setIsLoading(false);
      return;
    };

    checkIsLoggedIn();
  }, []);

  const onLogIn = (newToken: string) => {
    setIsLoggedIn(true);
    setToken(newToken);
    localStorage.setItem("token", newToken);
  };

  return <AuthContext.Provider value={{ token, onLogIn, isLoading, isLoggedIn }}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);

  if (authContext == null) {
    throw Error("No Auth context found. You forgot to add a consumer/provider");
  }
  return authContext;
};
