import { EstimationExperimentSimple, ParsedData, SolverResult, SolverResultSimple } from "../../utils/types";
import { SolverComparisonAction } from "./solverComparisonReducer";
import React from "react";
import { useAuthContext } from "../../Context/AuthContext";
import Papa from "papaparse";

export const useRequestHelpers = (dispatch: React.Dispatch<SolverComparisonAction>) => {
  const { token } = useAuthContext();
  const fetchSolverResultDetail = async (solverResult: SolverResultSimple) => {
    const response = await fetch(solverResult.href, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
    dispatch({ type: "SET_SOLVER_RESULT_DETAIL", solverResult: await response.json() });
  };

  const fetchExperimentDetail = async (selectedExperiment: EstimationExperimentSimple) => {
    const response = await fetch(selectedExperiment.href, { headers: { Authorization: `JWT ${token}` } });
    const responseJson = await response.json();
    dispatch({ type: "SET_SOLVER_RESULTS", solverResults: responseJson.solver_results });

    responseJson.solver_results.forEach((solverResult: SolverResultSimple) => {
      fetchSolverResultDetail(solverResult);
    });
  };

  const fetchSolverResultData = async (solverResult: SolverResult) => {
    const response = await fetch(solverResult.data_href, { headers: { Authorization: `JWT ${token}` } });
    const csvData = await response.text();

    const csv = await Papa.parse<ParsedData>(csvData, { header: true, dynamicTyping: true });
    dispatch({ type: "SET_SOLVER_RESULT_DATA", data: csv.data, name: solverResult.name });
  };

  return { fetchExperimentDetail, fetchSolverResultData };
};
